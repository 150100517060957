/* global google */
import logging from '@propertypal/shared/src/services/logging';
import { Circle, MarkerType } from '@propertypal/shared/src/types/map';
import { LatLng } from '@propertypal/shared/src/types/marker';
import platform from '@propertypal/shared/src/utils/platform';
import React, { Component, ReactNode } from 'react'; // Import useRef and useEffect
import config from '../../../../../app.config.json';
import { createMapMarker } from '../../utils/map';
import MapContainer from './GoogleMap.style';

export type PlaceResult = google.maps.places.PlaceResult & { type: string; address: string; lat: number; lng: number };

export interface InfoWindow {
  lat: number;
  lng: number;
  content: {
    image?: string;
    text?: string;
  };
}

interface Props {
  lat?: number;
  lng?: number;
  zoom?: number;
  width: number | string;
  height: number | string;
  gestureHandling?: string;
  markers?: MarkerType[];
  circles?: Circle[];
  infoWindows?: InfoWindow[];
  polygons?: (object | undefined)[];
  activeMarker?: MarkerType | null;
  hideStreetView?: boolean;
  onMarkerClick?: (marker: any) => void;
  onRegionChange?: () => void;
  children?: ReactNode;
  onLoad?: () => void;
  inputRef?: React.RefObject<HTMLInputElement>;
  onPlaceSelect?: (place: PlaceResult) => void;
}

export const DEFAULT_ZOOM = 8;
export const DEFAULT_LAT = 54.4;
export const DEFAULT_LNG = -5.9;
export const DEFAULT_COORD = {
  latitude: DEFAULT_LAT,
  longitude: DEFAULT_LNG,
  mapZoomLevel: DEFAULT_ZOOM,
};

const PLACE_FIELDS = ['formatted_address', 'geometry', 'types', 'name'];

// eslint-disable-next-line max-len
export const SCRIPT_SRC = `https://maps.googleapis.com/maps/api/js?key=${config.googleMapsWebKey}&libraries=places&callback=initMap&loading=async&v=3`;

let map: google.maps.Map | null = null;

export const getMapInstance = () => map;

// testing use only
export const setMapInstance = (instance: any) => {
  map = instance;
};

export const getZoom = () => {
  return map?.getZoom() || DEFAULT_ZOOM;
};

export const getCenter = () => {
  if (map) {
    const center = map.getCenter();

    if (center) {
      return { lat: center.lat(), lng: center.lng() };
    }
  }

  return { lat: DEFAULT_LAT, lng: DEFAULT_LNG };
};

export const getLatLngFromPoint = (x: number, y: number) => {
  if (map) {
    const bounds = map.getBounds();
    const projection = map.getProjection();
    const zoom = map.getZoom();

    if (bounds && projection && zoom) {
      const topRight = projection.fromLatLngToPoint(bounds.getNorthEast());
      const bottomLeft = projection.fromLatLngToPoint(bounds.getSouthWest());
      const scale = 2 ** zoom;

      if (topRight && bottomLeft) {
        const worldPoint = new google.maps.Point(x / scale + bottomLeft.x, y / scale + topRight.y);
        const latLng = projection.fromPointToLatLng(worldPoint);

        if (latLng) {
          return { lat: latLng.lat(), lng: latLng.lng() };
        }
      }
    }
  }

  return null;
};

export const centerToPolygon = (polygons: number[][][][], padding: number | google.maps.Padding) => {
  if (map) {
    const bounds = new google.maps.LatLngBounds();

    polygons.forEach((polygon) => {
      polygon[0].forEach((coord) => {
        bounds.extend(new google.maps.LatLng(coord[1], coord[0]));
      });
    });

    map.fitBounds(bounds, padding);
  }
};

export const centerToBounds = (latLngs: LatLng[], padding: number) => {
  if (map) {
    const bounds = new google.maps.LatLngBounds();

    latLngs.forEach((latLng) => {
      bounds.extend(new google.maps.LatLng(latLng.lat, latLng.lng));
    });

    map.fitBounds(bounds, padding);
  }
};

export const getBounds = () => {
  if (map) {
    const bounds = map.getBounds();

    if (bounds) {
      const northEast = bounds.getNorthEast();
      const southWest = bounds.getSouthWest();

      return {
        north: northEast.lat(),
        east: northEast.lng(),
        south: southWest.lat(),
        west: southWest.lng(),
      };
    }
  }

  return undefined;
};

const getGoogleMapsType = (type?: string) => {
  const placeMaps: Record<string, string> = {
    church: 'Church',
    hindu_temple: 'Temple',
    hospital: 'Hospital',
    mosque: 'Mosque',
    primary_school: 'School',
    school: 'School',
    secondary_school: 'School',
    synagogue: 'Synagogue',
    university: 'University',
    administrative_area_level_1: 'Home',
    administrative_area_level_2: 'Home',
    administrative_area_level_3: 'Home',
    administrative_area_level_4: 'Home',
    administrative_area_level_5: 'Home',
    administrative_area_level_6: 'Home',
    administrative_area_level_7: 'Home',
    archipelago: 'Home',
    colloquial_area: 'Home',
    continent: 'Home',
    country: 'Home',
    geocode: 'Home',
    intersection: 'Home',
    locality: 'Home',
    neighborhood: 'Home',
    place_of_worship: 'Place of Worship',
    plus_code: 'Home',
    political: 'Home',
    postal_code: 'Home',
    postal_code_prefix: 'Home',
    postal_code_suffix: 'Home',
    postal_town: 'Home',
    premise: 'Home',
    room: 'Home',
    route: 'Home',
    street_address: 'Home',
    street_number: 'Home',
    sublocality: 'Home',
    sublocality_level_1: 'Home',
    sublocality_level_2: 'Home',
    sublocality_level_3: 'Home',
    sublocality_level_4: 'Home',
    sublocality_level_5: 'Home',
    subpremise: 'Home',
    town_square: 'Home',
  };

  if (type && placeMaps?.[type]) return placeMaps[type];

  return 'Work';
};

const initMapInstance = (
  gestureHandling?: string,
  lat?: number,
  lng?: number,
  zoom?: number,
  hideStreetView?: boolean,
) => {
  const mapElement = document.getElementById('map');
  const mapContainer = document.getElementById('map_container');

  logging.logCrumb({
    message: 'New Map Instance',
    metaData: {
      hasMapGlobal: !!map,
      hasMapElement: !!mapElement,
    },
  });

  if (map && mapElement && mapContainer) {
    map.setOptions({
      center: { lat: lat || DEFAULT_LAT, lng: lng || DEFAULT_LNG },
      zoom: zoom || DEFAULT_ZOOM,
      gestureHandling,
      streetViewControl: !hideStreetView,
    });

    mapElement.style.display = 'block';
    mapContainer.appendChild(mapElement);
  } else if (mapContainer) {
    // handles instances where fast refresh clears map instance, but not the element
    if (!map && mapElement) {
      mapElement.remove();
    }

    const newMap = document.createElement('div');
    newMap.id = 'map';

    mapContainer.appendChild(newMap);

    map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
      center: { lat: lat || DEFAULT_LAT, lng: lng || DEFAULT_LNG },
      zoom: zoom || DEFAULT_ZOOM,
      scrollwheel: gestureHandling === 'greedy' ? true : null,
      gestureHandling,
      zoomControl: false,
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: !hideStreetView,
      streetViewControlOptions: {
        position: google.maps.ControlPosition?.RIGHT_TOP,
      },
      mapId: config.googleMapsId,
    });
    map.getStreetView().setMotionTracking(false);
  }
};

const removeMapInstance = () => {
  const mapElement = document.getElementById('map');

  if (map) {
    map.getStreetView().setVisible(false);
    map.setMapTypeId('roadmap');
  }

  if (mapElement) {
    mapElement.style.display = 'none';
    document.body.appendChild(mapElement);
  }
};

const createMapInstance = (
  gestureHandling: string | undefined,
  lat: number | undefined,
  lng: number | undefined,
  zoom: number | undefined,
  hideStreetView: boolean | undefined,
  callback: () => void,
) => {
  const script = document.querySelector<HTMLScriptElement>(`script[src="${SCRIPT_SRC}"]`);

  if (!script) {
    const googleMapScript = document.createElement('script');
    googleMapScript.src = SCRIPT_SRC;
    googleMapScript.async = true;
    googleMapScript.onload = () => {
      googleMapScript.setAttribute('data-loaded', 'true');
    };

    // @ts-ignore
    window.initMap = async () => {
      initMapInstance(gestureHandling, lat, lng, zoom, hideStreetView);
      await callback();
    };

    document.head.appendChild(googleMapScript);
  } else if (script && script.hasAttribute('data-loaded') && typeof google !== 'undefined') {
    initMapInstance(gestureHandling, lat, lng, zoom, hideStreetView);
    callback();
  }
};

class GoogleMap extends Component<Props> {
  markers: google.maps.marker.AdvancedMarkerElement[] = [];

  infoWindows: google.maps.marker.AdvancedMarkerElement[] = [];

  polygons: google.maps.Data.Feature[][] = [];

  circles: google.maps.Circle[] = [];

  listener?: google.maps.MapsEventListener;

  autocomplete: google.maps.places.Autocomplete | null = null;

  componentDidMount() {
    createMapInstance(
      this.props.gestureHandling,
      this.props.lat,
      this.props.lng,
      this.props.zoom,
      this.props.hideStreetView,
      async () => {
        if (map) {
          await google.maps.importLibrary('marker');

          this.listener = google.maps.event.addListener(map, 'idle', () => {
            if (this.props.onRegionChange) {
              this.props.onRegionChange();
            }
          });

          map.data.setStyle((feature) => {
            return {
              fillColor: feature.getProperty('fillColor') as string,
              fillOpacity: feature.getProperty('fillOpacity') as number,
              strokeColor: feature.getProperty('strokeColor') as string,
              strokeWeight: feature.getProperty('strokeWeight') as number,
            };
          });

          // Initialize Autocomplete if enabled
          if (this.props.inputRef?.current) {
            this.initAutocomplete();
          }
        }

        this.updateMarkers();
        this.updatePolygons();
        this.updateCircles();
        this.updateInfoWindows();
        if (this.props.onLoad) this.props.onLoad();
      },
    );
  }

  componentDidUpdate(prevProps: Props) {
    if (
      JSON.stringify(prevProps.markers) !== JSON.stringify(this.props.markers) ||
      prevProps.activeMarker?.id !== this.props.activeMarker?.id
    ) {
      this.updateMarkers();
    }

    if (JSON.stringify(prevProps.polygons) !== JSON.stringify(this.props.polygons)) {
      this.updatePolygons();
    }

    if (JSON.stringify(prevProps.circles) !== JSON.stringify(this.props.circles)) {
      this.updateCircles();
    }

    if (JSON.stringify(prevProps.infoWindows) !== JSON.stringify(this.props.infoWindows)) {
      this.updateInfoWindows();
    }
  }

  componentWillUnmount() {
    if (this.listener) {
      google.maps.event.removeListener(this.listener);
    }

    this.clearCircles();
    this.clearMarkers();
    this.clearPolygons();
    this.clearInfoWindows();

    removeMapInstance();
  }

  onPlaceSelect = (place?: google.maps.places.PlaceResult | null) => {
    if (!place?.geometry) {
      // User entered the name of a Place that was not suggested and
      // pressed the Enter key, or the Place Details request failed.
      return;
    }

    if (this.props.onPlaceSelect) {
      const address =
        place?.name && place?.formatted_address?.startsWith(place?.name)
          ? place.formatted_address
          : `${place.name}, ${place.formatted_address}`;

      const lat = place.geometry!.location!.lat();
      const lng = place.geometry!.location!.lng();

      // Call the callback with the selected place
      this.props.onPlaceSelect({ ...place, address, type: getGoogleMapsType(place?.types?.[0]), lat, lng });
    }
  };

  handleMapClick = async (event: google.maps.MapMouseEvent) => {
    if (!map || !event.latLng) return;

    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    // eslint-disable-next-line max-len
    const geocodingApiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${config.googleMapsWebKey}`;

    try {
      const response = await fetch(geocodingApiUrl);
      const data: google.maps.GeocoderResponse = await response.json();

      const geoPlace = data.results[0];

      if (geoPlace) {
        const service = new google.maps.places.PlacesService(map);

        service.getDetails(
          {
            placeId: geoPlace.place_id,
            fields: PLACE_FIELDS,
          },
          (place, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) this.onPlaceSelect(place);
          },
        );
      }
    } catch {
      // silent error
    }
  };

  // Autocomplete methods
  initAutocomplete = () => {
    if (this.props.inputRef?.current && typeof google !== 'undefined' && map) {
      this.autocomplete = new google.maps.places.Autocomplete(this.props.inputRef.current);
      this.autocomplete.bindTo('bounds', map); // Bind the search to the map's viewport

      this.autocomplete.setFields(PLACE_FIELDS); // Request specific fields

      this.autocomplete.addListener('place_changed', () => {
        const place = this.autocomplete?.getPlace();
        if (place?.geometry) {
          this.onPlaceSelect(place);

          // Optionally, center the map on the selected place
          if (place.geometry.viewport) map?.fitBounds(place.geometry.viewport);
          else map?.setCenter(place.geometry.location!);
        }
      });

      if (this.props.onPlaceSelect) map.addListener('click', this.handleMapClick);
    }
  };

  clearInfoWindows() {
    this.infoWindows.forEach((infoWindow) => {
      infoWindow.map = null;
    });

    this.infoWindows = [];
  }

  async updateInfoWindows() {
    this.clearInfoWindows();

    if (map && this.props.infoWindows) {
      this.props.infoWindows.forEach((item) => {
        const content = document.createElement('div');
        content.className = 'advanced-marker';

        if (item.content.image) {
          const img = document.createElement('img');
          img.src = item.content.image;
          content.appendChild(img);
        } else if (item.content.text) {
          const text = document.createElement('p');
          text.textContent = item.content.text;
          content.appendChild(text);
        }

        const marker = new google.maps.marker.AdvancedMarkerElement({
          position: new google.maps.LatLng(item.lat, item.lng),
          map,
          content,
        });

        this.infoWindows.push(marker);
      });
    }
  }

  clearPolygons() {
    if (map && this.polygons.length) {
      this.polygons.forEach((polygon) => {
        polygon.forEach((feature) => {
          if (map) map.data.remove(feature);
        });
      });

      this.polygons = [];
    }
  }

  updatePolygons() {
    this.clearPolygons();

    if (this.props.polygons) {
      this.props.polygons.forEach((polygon) => {
        if (map && polygon) this.polygons.push(map.data.addGeoJson(polygon));
      });
    }
  }

  clearCircles() {
    this.circles.forEach((circle) => {
      circle.setMap(null);
    });
  }

  updateCircles() {
    this.clearCircles();

    if (map && this.props.circles) {
      this.props.circles.forEach((circle) => {
        const newCircle = new google.maps.Circle({
          map,
          radius: circle.radius,
          center: new google.maps.LatLng(circle.latitude, circle.longitude),
          strokeColor: platform.select('rgba(238, 73, 0)', 'rgba(234, 74, 109, 0)'),
          strokeWeight: 3,
          fillColor: platform.select('rgba(238, 73, 0)', 'rgba(234, 74, 109, 0)'),
          fillOpacity: 0.2,
        });

        this.circles.push(newCircle);
      });
    }
  }

  clearMarkers() {
    this.markers.forEach((marker) => {
      marker.map = null;
    });
  }

  updateMarkers() {
    if (map && !this.props.markers) {
      this.clearMarkers();
    }

    if (map && this.props.markers) {
      const prevMarkers: google.maps.marker.AdvancedMarkerElement[] = [...this.markers];
      const nextMarkers: google.maps.marker.AdvancedMarkerElement[] = [];

      this.props.markers.forEach((marker) => {
        const { activeMarker } = this.props;

        // Find if marker is already rendered on map
        const index = prevMarkers.findIndex((prevMarker) => {
          if (prevMarker.position) {
            // @ts-ignore
            const wasActive = prevMarker.content.className.includes(
              platform.select('pp-map-marker-active', 'pn-map-marker-active'),
            );
            const isActive =
              !!activeMarker &&
              prevMarker.position.lat === activeMarker.lat &&
              prevMarker.position.lng === activeMarker.lng;

            return (
              prevMarker.position.lat === marker.lat && prevMarker.position.lng === marker.lng && isActive === wasActive
            );
          }

          return false;
        });

        if (index >= 0) {
          // Keep marker rendered
          const slice = prevMarkers.splice(index, 1)[0];
          nextMarkers.push(slice);
        } else {
          // Render new map marker
          const isActive = !!activeMarker && marker.lat === activeMarker.lat && marker.lng === activeMarker.lng;
          const mMarker = new google.maps.marker.AdvancedMarkerElement({
            content: createMapMarker(isActive, marker),
            position: marker,
            map,
          });

          mMarker.addListener('click', () => {
            if (this.props.onMarkerClick) {
              this.props.onMarkerClick(marker);
            }
          });

          nextMarkers.push(mMarker);
        }
      });

      // Clear any old markers
      prevMarkers.forEach((marker) => {
        marker.map = null;
      });

      this.markers = nextMarkers;
    }
  }

  render() {
    return (
      <MapContainer
        data-testid="google-map"
        id="map_container"
        style={{ width: this.props.width, height: this.props.height }}
      >
        {this.props.children}
      </MapContainer>
    );
  }
}

export default GoogleMap;

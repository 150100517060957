import MarkerIcon from '@propertypal/shared/src/resources/icons/map/map-marker-icon.svg?url';
import { MarkerType } from '@propertypal/shared/src/types/map';

export const createMapMarker = (active: boolean, marker: MarkerType) => {
  const content = document.createElement('div');
  content.className = `google-map-marker ${active ? 'google-map-marker-active' : ''}`;

  if (marker.units === undefined) {
    const markerIcon = marker?.icon?.[active ? 'selected' : 'default']?.src;
    const img = document.createElement('img');
    img.src = markerIcon || MarkerIcon.src;
    if (!markerIcon) img.style.maxWidth = '60%';
    else content.className = '';
    content.append(img);
  } else if (marker.properties && marker.properties.length === 1) {
    const div = document.createElement('div');
    div.className = 'google-marker-agent-logo';
    // @ts-ignore
    if (marker.properties[0].account.agentLogos) {
      // @ts-ignore
      div.style.backgroundImage = `url(${marker.properties[0].account.agentLogos[0].url})`;
      // @ts-ignore
      div.style.backgroundColor = marker.properties[0].account.agentLogos[0].backgroundColour;
    }
    content.append(div);
  } else {
    const label = document.createElement('p');
    label.innerHTML = !marker.units ? '' : marker.units && marker.units < 100 ? marker.units.toString() : '+';
    label.className = marker.units && marker.units < 100 ? '' : 'pp-marker-plus';
    content.append(label);
  }

  return content;
};

export default createMapMarker;

import styled from 'styled-components';

const MapContainer = styled.div`
  position: relative;

  .gm-style-iw {
    padding: 5px !important;

    .info-window-image {
    }

    .info-window-text {
      max-width: 120px;
      max-height: 60px;
      min-height: 20px;
      display: flex;
      align-items: center;
    }

    .gm-style-iw-d {
      overflow: auto !important;
    }
  }

  .advanced-marker {
    position: relative;
    background-color: white;
    border-radius: 4px;
    padding: 5px;

    img {
      max-width: 120px;
      max-height: 60px;
    }

    :after {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      bottom: -10px;
      left: 0;
      right: 0;
      margin: auto;

      border-top: 10px solid white;
    }
  }

  .google-map-marker {
    background-color: rgba(48, 48, 54, 0.75);
    height: 44px;
    width: 44px;
    padding: 4px;
    border-radius: 5px;
    position: relative;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;

    .google-marker-agent-logo {
      width: 36px;
      height: 36px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    p {
      text-align: center;
      color: ${({ theme }) => theme.white};
      font-size: 16px;
      font-weight: 700;
    }

    &:hover {
      background-color: ${({ theme }) => theme.primary};

      &:before {
        border-top-color: ${({ theme }) => theme.primary};
      }
    }

    &:before {
      position: absolute;
      right: 50%;
      bottom: -8px;
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      z-index: 50;
      border-color: rgba(0, 0, 0, 0);
      border-width: 8px 8px 0 8px;
      border-top-color: rgba(48, 48, 54, 0.75);
      margin-right: -8px;
    }
  }

  .google-map-marker-active {
    background-color: ${({ theme }) => theme.primary};

    &:before {
      border-top-color: ${({ theme }) => theme.primary};
    }
  }
`;

export default MapContainer;
